<template>
  <div>
    <Section1 :keyWord="'Si estás en ASNEF, puedes conseguir un préstamo con nosotros'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Préstamos particulares con Asnef</strong></h2>
      
      <p>¿Estás en un fichero de la Asnef y no recuerdas ni por qué? Entrar en estos listados sin darse cuenta es mucho más habitual de lo que imaginas.</p>
      <p>Un error de facturación con una compañía telefónica, algún recibo devuelto… unos cuantos euros son suficientes para entrar en una lista de morosos.</p>
      <p>El problema viene cuando quieres solicitar un crédito en una entidad bancaria tradicional, ya que lo primero que van a hacer es visitar estas bases de datos. Si verifican que estás dentro, lo más probable es que no te lo concedan.</p>
      <p>Pero no tienes nada de lo que preocuparte. Olvídate de recopilar decenas de documentos para intentar salir de estos ficheros.</p>
      <p>Si necesitas dinero urgente tienes a tu disposición los nuevos¿Todavía no sabes cómo <strong>préstamos particulares con Asnef</strong> de Ibancar funcionan? Aquí tienes todos los detalles.</p>

      <h3 class="naranja"><strong>Préstamos particulares con Asnef en cuestión de minutos</strong></h3>
      <p class="">Los <strong>préstamos particulares con Asnef</strong> de Ibancar se basan en la flexibilidad, la confianza y la comodidad para los clientes.</p>
      <p>Ibancar es consciente de que el hecho de estar incluido en los ficheros de la Asnef no implica que la persona solicitante no tenga los medios suficientes para satisfacer los pagos de un crédito.</p>
      <p>Así de sencillo. La inclusión en los listados de la Asnef deja de ser un impedimento para acceder a un préstamo.</p>
      <p>Pero esto es solo el principio. Los nuevos préstamos particulares con Asnef de Ibancar son concedidos en cuestión de minutos prescindiendo del eterno proceso de validación de los créditos tradicionales.</p>

      <h3 class="naranja"><strong>Cómo solicitar los préstamos particulares con Asnef de Ibancar sin moverse de casa</strong></h3>
      <p>¿Sabías que los <strong>préstamos particulares con Asnef</strong> de Ibancar se tramitan por completo de manera online?</p>
      <p>Sí, has leído bien. Ahora mismo puedes solicitar tu crédito en Ibancar sin levantarte del sofá y disfrutar del dinero en tu cuenta en menos de 24 horas. Te explicamos el proceso paso a paso:</p>
      <ul>
        <li>Calcula el dinero que necesitas y rellena el formulario de solicitud con tus datos.</li>
        <li>En pocos minutos recibirás una propuesta de préstamo.</li>
        <li>Acepta la propuesta, aporta la documentación requerida y  firma el contrato de forma online.</li>
        <li>En un plazo aproximado de 24 horas (en ocasiones muchas menos) tendrás el dinero solicitado en tu cuenta.</li>
      </ul>

      <h3 class="naranja"><strong>¿Es cierto que en los préstamos particulares con Asnef se utiliza el coche como aval?, ¿Cuáles son las condiciones?</strong></h3>
      <p>Cierto. En los <strong>préstamos particulares con Asnef</strong>, tu coche actúa como aval.</p>
      <p>Pero a diferencia de otras entidades, en las que el coche se te retira y se guarda en un depósito hasta que se liquide el crédito o debes pagar un alquiler para usarlo, en Ibancar, el aval del coche es un acto simbólico.</p>
      <ul>
        <li>Vas a poder seguir conduciendo tu vehículo sin ningún tipo de restricción.</li>
        <li>En ningún momento se cambia la titularidad del coche. Tampoco tendrás que pagar un renting o un alquiler para conducirlo.</li>
        <li>El coche nunca se te retira ni se almacena en un depósito.</li>
        <li>No se solicita una copia de las llaves ni la documentación original.</li>
        <li>El préstamo incluye un pack de servicios exclusivo para clientes de Ibancar que incluye una revisión técnica de mantenimiento anual, meses de carencia, días de cortesía entre otros.</li>
      </ul>

      <p>Si estás en  ASNEF y necesitas dinero Ibancar puede ser tu solución. </p>
      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
        <div class="card m-4">
          <a href="../prestamos-asnef" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Préstamo con ASNEF</h5>
                <span class="caret-sign">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                  </svg>
                </span>
              <p class="card-text pl-1 pt-2">Obtén un préstamo con ASNEF. Te ayudamos a conseguir el dinero que necesitas, incluso si estás en ASNEF.</p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'PrestamosParticularesAsnef',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Prestamos particulares con asnef ¡Fácil! | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'¿Estás en un fichero de la Asnef y no recuerdas ni por qué? Entrar en estos listados sin darse cuenta es mucho más habitual de lo que imaginas.'},
        {name:'keywords', content:'prestamos particulares con asnef'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>